import { Route, Routes } from 'react-router-dom';
import ContactPage from './pages/ContactPage';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import GalleryItemDetail from './pages/GalleryPage/GalleryItemDetail';
import MainPage from './pages/MainPage';
import MenuPage from './pages/MenuPage';
import MenuSetPage from './pages/MenuSetPage';
import SubcategoryPage from './pages/SubcategoryPage/SubcategoryPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/menu" element={<MenuPage />} />
      <Route path="/menu/:subcategory" element={<SubcategoryPage />} />
      <Route path="/sety" element={<MenuSetPage />} />
      <Route path="/galerie/:id" element={<GalleryItemDetail />} />
      <Route path="/galerie" element={<GalleryPage />} />
      <Route path="/kontakt" element={<ContactPage />} />
    </Routes>
  );
};

export default AppRoutes;
