import { GalleryData } from '../pages/GalleryPage/GalleryPage';
import foodData from './galleryData.json';

const useGalleryData = () => {
  const data = foodData;
  
  const getCommonItems = () => {
    return data.common as GalleryData[];
  };
  
  const getItemData = (id?: string): GalleryData | undefined => {
    const commonItems = getCommonItems();

    if (!id) {
      return undefined;
    }
    return commonItems?.find((item) => item.id === id);
  };

  return {
    getCommonItems,
    getItemData,
  };
}

export default useGalleryData;
