import { ConstructionOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, ToggleButtonGroup } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../img/budka_logo.png';

const pages = [{
    label: 'Úvod',
    path: '/'
  }, {
    label: 'Menu / nabídka',
    path: '/menu'
  }, {
    label: 'Menu sety',
    path: '/sety'
  }, {
    label: 'Fotogalerie',
    path: '/galerie'
  }, {
    label: 'Kontakt',
    path: '/kontakt',
  },
];

const LogoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  '& img': {
    left: 0,
    height: 80,
    marginTop: 20,
  },
});

const StyledAppBar = styled(AppBar)({
  background: '#151f42',
  position: 'fixed',
  overflow: 'hidden',
  '.MuiButton-root': {
    color: '#808080',
    borderRadius: 0,
    '&:first-of-type': {
      borderRadius: '8px 0px 0px 8px',
    },
    '&:last-of-type': {
      borderRadius: '0px 8px 8px 0px',
    },
    '&:hover': {
      backgroundColor: '#595bca',
      color: '#ffffff',
    },
  },
  '.MuiButton-outlined': {
    backgroundColor: '#595bca',
    color: '#ffffff',
  },
  '.MuiButton-contained': {
    backgroundColor: '#151f42',
    color: '#808080',
  },
});

const MainMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>, path?: string) => {
    setAnchorElNav(event.currentTarget);
    if (path) {
      navigate(path);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSelectNavItem = (path: string) => {
    setAnchorElNav(null);
    navigate(path);
  };

  const actPage = pages.slice(1).find((page) => location.pathname.startsWith(page.path)) ?? pages[0];

  return (
    <StyledAppBar position="fixed">
      <Container maxWidth="xl">
        <LogoContainer>
          <img src={logo} alt="Budka u hřiště" />
        </LogoContainer>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem selected={page.path === actPage?.path} key={page.path} onClick={() => handleSelectNavItem(page.path)}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <span>{actPage?.label}</span>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.path}
                onClick={(event) => handleOpenNavMenu(event, page.path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
                variant={page.path === actPage.path ? 'outlined' : 'contained'}
              >
                {page.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default MainMenu;
