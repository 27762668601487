import { styled } from '@mui/material';
import { SubcategoryData } from './SubcategoryPage';

interface SubcategoryItemProps {
  item: SubcategoryData;
}

const DetailFoodCard = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& .text-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    '& .title': {
      fontSize: 20,
      fontWeight: 'bold',
    },
    '& .description': {
      fontSize: 18,
    },
  },
  '& .img-container': {
    overflow: 'hidden',
    minWidth: 150,
    width: 150,
    height: 145,
    paddingRight: 10,
    marginRight: 10,
    '@media (min-width: 992px)': {
      width: 340,
      height: 278,
      paddingRight: 20,
      marginRight: 20,
    },
    borderRight: 'solid 1px #f0f0f0',
    '& img': {
      width: '100%',
    },
  }
});

const SubcategoryItem: React.FC<SubcategoryItemProps> = ({ item }) => {
  return (
    <DetailFoodCard key={item.label}>
      <div className="img-container">
        <img
          src={`${process.env.PUBLIC_URL}/images/${item.img}`} 
          alt={item.label}
        />
      </div>
      <div className="text-container">
        <div className="title">{item.label}</div>
        <div className="description">{item.desc}</div>
      </div>
    </DetailFoodCard>
  );
};

export default SubcategoryItem;
