import { styled, Typography } from '@mui/material';

const Description = styled('div')({
  marginTop: 40,
  marginBottom: 40,
});

const ImgContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const MainPage = () => {
  return (
    <>
      <Typography fontFamily="Roboto,Helvetica,Arial,sans-serif" variant="h3" noWrap>Vítej hladový návštěvníku!</Typography>
      <Description>
        <p>
          Vítejte na stránkách bistra "Budka u hřiště". Na těchto stránkách se o nás dozvíte trochu více, můžete si prohlédnout naši nabídku jídel a drinků, dozvědět se víc o připravovaných akcích a v neposlední řadě můžete také objednat jídlo až k Vám domů.
        </p>
        <p>
          Těšíme se na vaši návštěvu.
        </p>
        <p>
          <br />
          Váš prezident budky.
        </p>
      </Description>
      <ImgContainer>
        <img className="img-fluid" 
          src={`${process.env.PUBLIC_URL}/images/budka.jpg`} 
          alt="Budka u hřiště"
        />
      </ImgContainer>
    </>
  )
};

export default MainPage;
