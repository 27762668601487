import { styled } from '@mui/material';
import MainMenu from './MainMenu/MainMenu';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 40,
  maxWidth: 1460,
  height: '100%',
});

export interface LayoutProps {
  children: React.ReactElement;
}

const Layout: React.FC<LayoutProps> =({children}) =>{
  return(
    <div>
      <div style={{ height: 160 }}>
        <MainMenu/>
      </div>
      <Container>
        {children}
      </Container>
    </div>
  )
}

export default Layout;
