import { styled, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useGalleryData from '../../data/useGalleryData';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  alignItems: 'center',
});

const GalleryItemDetail: React.FC = () => {
  const { getItemData } = useGalleryData();
  const routeParams = useParams();
  const item = getItemData(routeParams.id);

  return (
    <Container>
      <Typography fontFamily="Roboto,Helvetica,Arial,sans-serif" variant="h3" noWrap>Galerie</Typography>
      <img
        style={{ maxWidth: 1200 }}
        src={`${process.env.PUBLIC_URL}/images/gallery/${item?.img}`} 
        alt={item?.label}
      />
      <div>
        <div>{item?.label}</div>
        <div>{item?.desc}</div>
      </div>
    </Container>
  )
};

export default GalleryItemDetail;
