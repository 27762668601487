import { Subcategory } from '../pages/SubcategoryPage/SubcategoryPage';
import foodData from './foodData.json';

const useFoodData = () => {
  const data = foodData as Record<string, Subcategory>;
  
  const getCategories = () => {
    const keys = Object.keys(data);
    return keys.map((key) => ({
      label: data[key].label,
      img: data[key].img,
      path: `/${key}`,
    }));
  };
  
  const getSubcategoryData = (subcategory?: string) => {
    const keys = Object.keys(data);
    if (!subcategory || !keys.includes(subcategory)) {
      return null;
    }
    return data[subcategory];
  };

  return {
    getCategories,
    getSubcategoryData,
  };
}

export default useFoodData;
