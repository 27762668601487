import { styled } from '@mui/material';
import React from 'react';

export interface OpenHours {
  day: string;
  hours: string;
}

const openHours: OpenHours[] = [{
  day: 'Pondělí',
  hours: '16:00 - 20:00',
}, {
  day: 'Úterý',
  hours: '16:00 - 20:00',
}, {
  day: 'Středa',
  hours: '16:00 - 20:00',
}, {
  day: 'Čtvrtek',
  hours: '16:00 - 20:00',
}, {
  day: 'Pátek',
  hours: '16:00 - 20:00',
}, {
  day: 'Sobota',
  hours: '16:00 - 20:00',
}, {
  day: 'Neděle',
  hours: '16:00 - 20:00',
}];

const PageContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  gap: 30,
  '& .header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& h1, h5': {
    margin: 0,
  },
  '& p': {
    marginTop: 0,
    marginBottom: 8,
  },
});

const OpenHourRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: 250,
  justifyContent: 'space-between',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
});

const InfoContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '& .label': {
    justifySelf: 'end',
  },
  '& .value': {
    marginLeft: 8,
    justifySelf: 'start',
    fontWeight: 'bold',
  }
});

const infoData = [{
  label: 'Odpovědná osoba',
  value: 'Radek Juchelka',
}, {
  label: 'email',
  value: 'budkauhriste@gmail.com',
}, {
  label: 'mobil pro objednávky a osobní vyzvednutí',
  value: '731 758 216',
}, {
  label: 'facebook/instagram',
  value: 'budkauhriste',
}, {
  label: 'telefon',
  value: '733 438 538 / 730 691 237',
}, {
  label: 'ič',
  value: '17164214',
}];

const ContactPage = () => {
  return (
    <PageContent>
      <div className="header">
        <h1>Otevírací doba</h1>
        <span>od 1.1. 2023</span>
      </div>
      <div>
        {openHours.map((item) => (
          <OpenHourRow key={item.day}>
            <span>{item.day}</span>
            <span>{item.hours}</span>
          </OpenHourRow>
        ))}
      </div>
      <div>Při jakékoliv akci na hřištích se pracovní doba nastavuje individuálně.</div>
      <InfoContainer>
        {infoData.map((item) => (
          <React.Fragment key={item.label}>
            <span className='label'>{item.label}:</span>
            <span className='value'>{item.value}</span>
          </React.Fragment>
        ))}
      </InfoContainer>
    </PageContent>
  )
};

export default ContactPage;
