import { Button, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { GalleryData } from './GalleryPage';

interface GalleryItemProps {
  item: GalleryData;
}

const DetailGalleryItem = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  '& .text-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    '& .title': {
      fontSize: 20,
      fontWeight: 'bold',
    },
    '& .description': {
      fontSize: 18,
    },
  },
  '& .img-container': {
    overflow: 'hidden',
    minWidth: 220,
    width: 220,
    height: 150,
    paddingRight: 10,
    marginRight: 10,
    '@media (min-width: 992px)': {
      width: 500,
      height: 300,
      paddingRight: 20,
      marginRight: 20,
    },
    borderRight: 'solid 1px #f0f0f0',
    '& img': {
      width: '100%',
    },
  }
});

const GalleryItem: React.FC<GalleryItemProps> = ({ item }) => {
  const location = useLocation();

  return (
    <DetailGalleryItem key={item.label}>
      <div className="img-container">
        <Link key={item.id} to={`${location.pathname}/${item.id}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/gallery/${item.img}`} 
            alt={item.label}
          />
        </Link>
      </div>
      <div className="text-container">
        <div className="title">{item.label}</div>
        <div className="description">{item.desc}</div>
      </div>
    </DetailGalleryItem>
  );
};

export default GalleryItem;
