import { styled, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFoodData from '../../data/useFoodData';
import SubcategoryItem from './SubcategoryItem';

export interface SubcategoryData {
  label: string;
  img: string;
  desc: string;
}

export interface Subcategory {
  label: string;
  img: string;
  items: SubcategoryData[];
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  alignItems: 'center',
});

const MenuContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: 40,
  justifyContent: 'center',
});

const SubcategoryPage: React.FC = () => {
  const params = useParams();
  const { getSubcategoryData } = useFoodData();
  const categoryData = getSubcategoryData(params.subcategory);

  return (
    <Container>
      <Typography fontFamily="Roboto,Helvetica,Arial,sans-serif" variant="h3" noWrap>{categoryData?.label}</Typography>
      <MenuContainer>
        {categoryData?.items?.length
          ? categoryData?.items?.map((item) => (
            <SubcategoryItem key={item.label} item={item} />
          )) : (
            <span>Aktuálně zde nejsou žádné položky</span>
          )
        }
      </MenuContainer>
    </Container>
  )
};

export default SubcategoryPage;
