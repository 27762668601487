import { styled, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import useFoodData from '../data/useFoodData';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  alignItems: 'center',
  justifyItems: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const MenuContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 40,
  justifyContent: 'center',
});

const FoodCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .description': {
    fontSize: 18,
  },
  '& img': {
    margin: 10,
    width: 340,
    height: 278,
  },
});

const MenuPage = () => {
  const location = useLocation();
  const { getCategories } = useFoodData();
  const categories = getCategories();

  return (
    <Container>
      <Typography fontFamily="Roboto,Helvetica,Arial,sans-serif" variant="h3" noWrap>Menu</Typography>
      <MenuContainer>
        {categories.map((item) => (
          <Link key={item.path} to={`${location.pathname}${item.path}`}>
            <FoodCard>
              <img className="img-fluid" 
                src={`${process.env.PUBLIC_URL}/images/${item.img}`} 
                alt={item.label}/> 
              <div className="description">{item.label}</div>
            </FoodCard>
          </Link>
        ))}
      </MenuContainer>
    </Container>
  )
};

export default MenuPage;
