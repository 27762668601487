import { styled, Typography } from '@mui/material';
import useGalleryData from '../../data/useGalleryData';
import GalleryItem from './GalleryItem';

export interface GalleryData {
  id: string;
  label: string;
  img: string;
  desc: string;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  alignItems: 'center',
});

const GalleryPage = () => {
  const { getCommonItems } = useGalleryData();
  const commonItems = getCommonItems();

  return (
    <Container>
      <Typography fontFamily="Roboto,Helvetica,Arial,sans-serif" variant="h3" noWrap>Galerie</Typography>
      {commonItems.map((item) => (
        <GalleryItem key={item.label} item={item} />
      ))}
    </Container>
  )
};

export default GalleryPage;
